import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { useGeoData } from '@bbx/search-journey/common/lib/useGeoData'
import { AddressBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { DealerProfile } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/DealerProfile'
import { KeyValue } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/KeyValuePair'
import { PhoneNumberButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberButton'
import { SellerInfoButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/SellerInfoButton'
import { WebsiteLink } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/WebsiteLink'
import { AdDetailMap } from '@bbx/search-journey/sub-domains/ad-detail/components/common/Map/AdDetailMap'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { getOrganisationProfileWeblink } from '@bbx/search-journey/sub-domains/ad-detail/lib/getLinkToDealerProfile'
import { Box } from '@wh-components/core/Box/Box'
import { Card } from '@wh-components/core/Card/Card'
import { Divider } from '@wh-components/core/Divider/Divider'
import { LayoutProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import React, { FunctionComponent, useMemo } from 'react'

interface BapContactBoxDealerBottomProps extends SpaceProps, LayoutProps {
    advertDetails: AdvertDetails
}

export const BapContactBoxDealerBottom: FunctionComponent<BapContactBoxDealerBottomProps> = ({ advertDetails, ...props }) => {
    const { name, image, websiteUrl, address, contactName, phoneNumbers } = useMemo(() => getContactData(advertDetails), [advertDetails])
    const geoData = useGeoData(advertDetails)
    const organisationProfileWeblink = getOrganisationProfileWeblink(advertDetails.organisationDetails.organisationDetailLinkList)

    return (
        <Card padding={0} backgroundColor="palette.white" testId="ad-detail-contact-box-dealer-bottom" {...props}>
            <DealerProfile
                name={name}
                image={image}
                link={websiteUrl}
                adId={advertDetails.id}
                taggingData={advertDetails.taggingData}
                testIdPrefix="bottom"
            />
            <Divider marginHorizontal="m" />
            <Box display="flex" flexDirection="column" gap="s" padding="m">
                <KeyValue
                    title="Adresse"
                    testIdPrefix="contact-box-dealer-bottom"
                    content={address && <AddressBox address={address} testIdPrefix="bottom" />}
                />
                <KeyValue title="Kontakt" testIdPrefix="contact-box-dealer-bottom" content={contactName} />
                <KeyValue title="Referenz" testIdPrefix="contact-box-dealer-bottom" content={advertDetails.advertiserReferenceNumber} />
                {websiteUrl && (
                    <WebsiteLink url={websiteUrl} adId={advertDetails.id} taggingData={advertDetails.taggingData} testIdPrefix="bottom" />
                )}
            </Box>
            <Box display="flex" flexDirection="column" gap="s" padding="m" paddingTop={0}>
                <PhoneNumberButton
                    phoneNumbers={phoneNumbers}
                    toggleWithContext={true}
                    adId={advertDetails.id}
                    taggingData={advertDetails.taggingData}
                    testIdPrefix="bottom"
                />
                {organisationProfileWeblink && (
                    <SellerInfoButton
                        link={organisationProfileWeblink}
                        clickTaggingActionEvent="addetail_more_ads_click"
                        taggingData={advertDetails.taggingData}
                        testIdPrefix="bottom"
                    >
                        Weitere Anzeigen von diesem Shop
                    </SellerInfoButton>
                )}
            </Box>
            {geoData.type !== 'none' && (
                <DynamicRendering<HTMLDivElement>
                    key="ad-detail-map"
                    offset="300px"
                    renderPlaceholder={(ref) => <Box ref={ref} height={150} testId="ad-detail-map-dynamic-placeholder" />}
                >
                    <Box paddingHorizontal={{ phone: 'm', tablet: 0 }}>
                        <AdDetailMap
                            height={150}
                            center={geoData.coordinates}
                            adId={advertDetails.id}
                            taggingData={advertDetails.taggingData}
                            circle={geoData.type === 'radius' ? geoData.radius : undefined}
                        />
                    </Box>
                </DynamicRendering>
            )}
        </Card>
    )
}
